<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-2">
        <div class="content-body">
          <div class="card">
            <div class="card-body p-1">
              <div class="row">
                <div class="col-6">
                  <h4 class="text-truncate">
                    <strong v-if="userData">{{ userData.name }} </strong>
                  </h4>

                  <div style="color: #00364f">
                    <strong>Address: </strong
                    ><span v-if="userData"
                      >{{ userData.address }} {{ userData.city }}
                      <span v-if="userData.state"> {{ userData.state.name }}</span>
                      {{ userData.pin }}</span
                    >
                  </div>

                  <div style="font-size: 10pt; color: #00364f">
                    <strong>[M]: </strong
                    ><span v-if="userData"> {{ userData.phone }}, </span>
                  </div>
                  <div style="font-size: 10pt; color: #00364f">
                    <strong>[E]: </strong>
                    <span v-if="userData"> {{ userData.email }}, </span>
                  </div>
                </div>
                <div class="col-6 text-end">
                  <div>
                    <span
                      v-if="reciptData.status == 'pending'"
                      class="text-white btn btn-sm"
                      style="
                        color: white;
                        background-color: #fc7f03;
                        width: 70px;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b> {{ reciptData.status }}</b>
                    </span>

                    <span
                      v-else
                      style="
                        color: white;
                        background-color: green;
                        width: 70px;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      {{ reciptData.status }}</span
                    >
                  </div>
<h4 class="mt-xl-5 mt-md-5 d-none d-md-block d-xl-block" >
  <strong style="color:#00364f"
                      class="heading "
                      v-if="reciptData.fromuser"
                      >{{ reciptData.fromuser.orgnization }}
                    </strong>
</h4>

<strong style="font-size: 10pt;color:#00364f"
                      class="d-block d-md-none d-xl-none"
                      v-if="reciptData.fromuser"
                      >{{ reciptData.fromuser.orgnization }}
                    </strong>

                    <div class="text-end" style="font-size: 10pt; color: #00364f; padding-top: 10px">
                      <strong>BILL TO</strong>
                    </div>

                    <div style="font-size: 10pt; color: #00364f">
                      <strong v-if="reciptData.fromuser"
                        >{{ reciptData.fromuser.name }}
                      </strong>
                    </div>
                    <div
                      style="font-size: 10pt; color: #00364f"
                      v-if="reciptData.fromuser"
                    >
                      {{ reciptData.fromuser.address }} ,<span
                        v-if="reciptData.fromuser.state"
                      >
                        {{ reciptData.fromuser.state.name }} ,</span
                      >
                      {{ reciptData.fromuser.city }} , {{ reciptData.fromuser.pin }}
                    </div>

                </div>
              </div>

         

              <hr />


              <div class="row"  style="
             
                  color: #00364f;

                  text-transform: capitalize;
                ">
                <div class="col">
                  <div><strong >Date</strong></div>
                </div>
                <div class="col text-end">
                  <div>{{ dateTime(reciptData.date) }}</div>
                </div>
              </div>

              <div class="row"  style="
             
             color: #00364f;

             text-transform: capitalize;
           ">
           <div class="col">
             <div><strong >Total Amount	</strong></div>
           </div>
           <div class="col text-end">
             <div>Rs.{{ reciptData.amount }}</div>
           </div>
         </div>


         <div class="row"  style="
             
             color: #00364f;

             text-transform: capitalize;
           ">
           <div class="col">
             <div><strong >Payment Mode	</strong></div>
           </div>
           <div class="col text-end">
             <div>{{ reciptData.paymentMode }}</div>
           </div>
         </div>


         <div class="row"  style="
             
             color: #00364f;

             text-transform: capitalize;
           ">
           <div class="col">
             <div><strong >Paid Amount		</strong></div>
           </div>
           <div class="col text-end">
             <div>Rs.{{ reciptData.amount }}</div>
           </div>
         </div>
              

              <table

                class="font"
                style="
                  width: 100%;
                  color: #00364f;
                  font-size: 10pt;
                  text-transform: capitalize;
                "
              >
                <!-- <tr>
                  <th class="alin-left" style="width: 10%">Date</th>
                  <td class="alin-left">{{ dateTime(reciptData.date) }}</td>
                  <th class="text-end">Total Amount</th>
                  <td class="text-end" style="width: 12%">Rs.{{ reciptData.amount }}</td>
                </tr>

                <tr>
                  <th class="alin-left text-truncate">Payment Mode:</th>
                  <td class="alin-left">{{ reciptData.paymentMode }}</td>
                  <th class="text-end">Paid Amount</th>

                  <td class="text-end" style="width: 12%">Rs.{{ reciptData.amount }}</td>
                </tr> -->

                <tr v-if="reciptData.paymentMode == 'Online'">
                  <th class="alin-left">Bank</th>
                  <td class="alin-left">{{ reciptData.bank }}</td>
                  <th class="text-end">Branch</th>

                  <td class="text-end" style="width: 12%">{{ reciptData.branch }}</td>
                </tr>
                <tr v-if="reciptData.paymentMode == 'Online'">
                  <th class="alin-left">IFSC</th>
                  <td class="alin-left">{{ reciptData.ifsc }}</td>
                  <th class="text-end">Account No</th>

                  <td class="text-end" style="width: 12%">{{ reciptData.accountno }}</td>
                </tr>

                <tr v-if="reciptData.paymentMode == 'Cheque'">
                  <th class="alin-left">Cheque No</th>
                  <td class="alin-left">{{ reciptData.checkno }}</td>
                  <th class="text-end"></th>

                  <td class="text-end" style="width: 12%"></td>
                </tr>
              </table>

              <table
                class="font"
                style="width: 100%; margin-top: 10px; text-transform: capitalize"
              >
                <tr style="background-color: #00364f; font-size: 10pt; color: white">
                  <th class="alin-left" style="padding: 5px 5px 5px 5px">Description</th>

                  <th class="text-end" style="padding: 5px 5px 5px 5px">Amount</th>
                </tr>
                <tr style="font-size: 10pt; color: #00364f">
                  <td style="padding: 5px 5px 5px 5px">{{ reciptData.txnType }}</td>

                  <td class="text-end" style="padding: 5px 5px 5px 5px">
                  Rs.  {{ reciptData.amount }}
                  </td>
                </tr>
              </table>

              <div
                style="
                  background-color: #f2f2f2;
                  padding: 5px 10px 5px 10px;
                  border-radius: 10px;
                  margin-top: 30px;
                "
              >
                <table
                  style="
                    width: 100%;
                    font-size: 10pt;
                    color: #00364f;
                    text-transform: capitalize;
                  "
                  class="font"
                >
                  <tr>
                    <td class="text-start">
                      <strong style="margin-right: 50px">In Words</strong>
                      <strong> {{netAmtInWords }} </strong>
                    </td>
                    <td class="text-end">
                      <strong style="margin-right: 50px">Total</strong>
                      <strong> Rs.{{ reciptData.amount }}</strong>
                    </td>
                  </tr>
                </table>
              </div>

              <div
                class="font"
                style="
                  text-align: center;
                  margin-top: 30px;
                  font-size: 10pt;
                  text-transform: capitalize;
                "
              >
                This is Computer Generate Invoice No Signature Required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InWordsNumber from "../../../mixins/numberToinword";
// import Banner from '../../../components/superadmin/comman/Banner.vue'
// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Packages",
  superadmin_id: "",
  mixins: [InWordsNumber],
  components: {},
  data() {
    return {
      netAmtInWords: '',
      reciptData: {},
      userData: {},
      serviceModules: [],
      inWords: "",
      invoiceData: {},
      totalAmt: null,
      number: null,
      ones: [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ],
      tens: [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ],
      scales: [
        "",
        "",
        "",
        "hundred ",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ],
    };
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    countDig(totalAmt) {
      var n = totalAmt;
      var count = 0;
      if (n >= 1) ++count;
      while (n / 10 >= 1) {
        n /= 10;
        ++count;
      }
      return count;
      // this.covertNumberTowor(count)
    },

    atOnse19(amt) {
      return this.ones.at(amt);
    },
    atTese99(amt) {
      var n = 0;
      var res = amt;
      n = parseInt(res / 10);
      amt = parseInt(res % 10);
      var txt1 = this.tens.at(n);
      if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    scales999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 100);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 100);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      // console.log(amt)
      if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo9999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 1000);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo99999(amt) {
      let count = this.countDig(amt);
      // console.log('99999')
      if (count == 4 || count == 5) {
        count = 4;
      }
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n) + " " + this.scales.at(count);
      // console.log(txt1)
      amt = parseInt(res % 1000);
      txt1 = n < 20 ? txt1 : this.atTese99(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        //    console.log(txt1)
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    covertNumberTowor() {
      // console.log('click')
      // console.log(this.totalAmt)
      let count = this.countDig(this.totalAmt);
      // console.log(count)
      if (this.totalAmt < 20) {
        count = 1;
      }
      switch (count) {
        case 1:
          this.inWords = this.atOnse19(this.totalAmt);
          break;
        case 2:
          this.inWords = this.atTese99(this.totalAmt);
          break;
        case 3:
          this.inWords = this.scales999(this.totalAmt);
          break;
        case 4:
          this.inWords = this.valTo9999(this.totalAmt);
          break;
        case 5:
          this.inWords = this.valTo99999(this.totalAmt);
          break;

        default:
          this.inWords = this.totalAmt;
      }
    },
    print() {
      // alert('hello')
      // Get HTML to print from element
      const prtHtml = document.getElementById("printableArea").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    lodaInvoices() {
      var Invoice = {};
      var id;
      Invoice = JSON.parse(localStorage.getItem("Invoice"));
      id = Invoice.id;
      // localStorage.setItem('Invoice', JSON.stringify(Invoice))

      this.$axios
        .get(`superadmin/clientservice/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.invoiceData = res.data.data;
          this.totalAmt = this.invoiceData.serviceCharge;
          // console.log('---------------------------------------------')
          // console.log(this.invoiceData.serviceModules)
          var mod = JSON.parse(this.invoiceData.serviceModules);
          // console.log(JSON.parse(this.invoiceData.serviceModules));

          this.$axios
            .post(
              `/superadmin/servicemodule`,
              { serviceModulesIds: mod },
              { headers: { Authorization: "Bearer " + localStorage.accessToken } }
            )
            .then((res) => {
              // console.log(res.data)
              this.serviceModules = res.data.data;
            });
          // console.log('---------------------------------------------')

          // mod = unserialize(this.invoiceData.serviceModules);

          // console.log(mod);
          // document.write('<br />'+"Unserialize the above data and go back to original array : " +'<br />');

          this.countDig();
          this.covertNumberTowor();
        });
    },
    loadUserApi() {
      this.$axios
        .post(
          `superadmin/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;

     
        });
    },

    clientPayServiceAmount() {
      this.$axios
        .get(`superadmin/transaction/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.reciptData = res.data.data;


          this.conertNumberToWords(this.reciptData.amount,'superadmin');

        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.clientPayServiceAmount();
      this.loadUserApi();
    }
  },
};
</script>

<style scoped>
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

@media print {
  /* body {
        -webkit-print-color-adjust: exact;
    } */

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666666666666%;
  }

  .col-sm-10 {
    width: 83.33333333333334%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666666666666%;
  }

  .col-sm-7 {
    width: 58.333333333333336%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666666666667%;
  }

  .col-sm-4 {
    width: 33.33333333333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.666666666666664%;
  }

  .col-sm-1 {
    width: 8.333333333333332%;
  }
}
</style>
